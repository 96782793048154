@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

* {
    box-sizing: border-box;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    background-color: #000000;
    background-image: linear-gradient(to right top, #000000, #040404, #080808, #0c0c0c, #0f0f0f);
}

